import './utilsinfo.css';
import IconAnonce from '../../assets/icon_anunturi.svg';
import IconArticles from '../../assets/icon_articole.svg';
import IconLegislate from '../../assets/icon_legislatie.svg';

const UtilsInfo = ({openMoal}) => {
    return (
        <div className='container'>
            <div className="row">
                <div className="col-xl-12">
                    <div className="block-contents text-center">
                        <div className="section-title about-title">
                            <h2>Informații utile</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-md-4 offset-md-0 col-sm-8 offset-sm-2">
                    <div className="card" >
                        <div className="card-img d-flex justify-content-between align-items-center">
                            <h2 className="card-title">Anunțuri</h2>
                            <img src={IconAnonce} className="card-img-info" alt="..." />
                        </div>
                        <div className="card-body">
                            <p className="card-text">
                                A fost lansată noua versiune a paginii oficiale <b>PROFADMIN</b>: <a href="/" className="go-to-factura-link" target="_blank"><i>www.profadmin.md</i></a> <br/>
                                A fost creat și pus aplicare un nou instrument de achitare electronic a facturilor/documentelor de plată, precum și de prezentare a indicilor contoarelor individuale. <br />
                                Pentru a accesa, apăsați <a href="/factura" className="go-to-factura-link" target="_blank"><b>PREZINTĂ INDICII</b></a>
                            </p>
                            <div className="btn-wepper">
                                <button onClick={(e) => openMoal(e, 'contentModal6')} className="bg-bth btn-full-width">Citește mai mult <i className="fal fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 offset-md-0 col-sm-8 offset-sm-2 mt-4 mt-md-0">
                    <div className="card" >
                        <div className="card-img d-flex justify-content-between align-items-center">
                            <h2 className="card-title">Articole</h2>
                            <img src={IconArticles} className="card-img-info" alt="..." />
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>
                            <div className="btn-wepper">
                                <button onClick={(e) => openMoal(e, 'contentModal7')} className="bg-bth btn-full-width">Citește mai mult <i className="fal fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 offset-md-0 col-sm-8 offset-sm-2 mt-4 mt-md-0">
                    <div className="card" >
                        <div className="card-img d-flex justify-content-between align-items-center">
                            <h2 className="card-title">Legislație</h2>
                            <img src={IconLegislate} className="card-img-info" alt="..." />
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>
                            <div className="btn-wepper">
                                <button onClick={(e) => openMoal(e, 'contentModal8')} className="bg-bth btn-full-width">Citește mai mult <i className="fal fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UtilsInfo;
