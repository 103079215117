import {useState} from "react";
import "./questions.css";
import {Accordion} from "react-bootstrap";
import ContactModal from "../modal/ContactModal";

const Questions = ({openMoal}) => {
  const [show, setShow] = useState(false);
  return (
    <div className={"container"}>
      <div className="row">
        <div className="col-xl-12">
          <div className="block-contents text-center">
            <div className="section-title about-title">
              <h2>Întrebări frecvente</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-xs-12 d-flex flex-column justify-content-between mb-5 mb-md-0">
          <div>
            <div className="block-contents mb-4">
              <div className="section-title">
                <span>Întrebări și răspunsuri</span>
              </div>
            </div>
            <p className="mb-3 card-text">
              Pentru noi contează calitatea serviciilor prestate clienților. Astfel, suntem disponibili pentru a răspunde la orice întrebare o aveți, accesând butonul <b onClick={(e) => setShow(true)} className="go-to-factura-link">ÎNTREABĂ-NE</b> sau la numerele de telefon indicate la rubrica <a href="#footer_section" className="go-to-factura-link"><b>CONTACTE</b></a>, în intervalul programului de lucru.
            </p>
          </div>
          <div className="btn-wepper">
            <button
              onClick={(e) => setShow(true)}
              className="bg-bth btn-read-more btn-send-indicators"
            >
              Întreabă-ne
            </button>
          </div>
        </div>
        <div className="col-md-6 col-xs-12 d-flex flex-column justify-content-between">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span>
                  Cum pot recepționa factura pe e-mail?
                </span>
                <div className="custom-accordion-button-icon"></div>
              </Accordion.Header>
              <Accordion.Body>
                Pentru a recepționa factura pe e-mail este necesar de a expedia o solicitare în adresa
                noastră, unde să fie indicate datele de identificare ale solicitantului și cod ID al unității
                (imobilului) pentru care se face solicitarea.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span>
                  Cum pot fi achitate facturile de plată?
                </span>
                <div className="custom-accordion-button-icon"></div>
              </Accordion.Header>
              <Accordion.Body>
                Lunar, după emiterea facturilor/documentelor de plată plătitorilor, acestea sânt disponibile
                în sistemele de plăți pe întreg teritoriul Republicii Moldova, precum și prin intermediul sistemelor de
                plăți internaționale „visa/mastercard”. <br />
                Achitarea serviciilor poate fi efectuată în numerar sau prin virament:
                <ul className="utils-info-list mb-2">
                  <li>
                    prin accesarea butonului <a href="/factura" className="go-to-factura-link" target="_blank"><b>ACHITĂ ONLINE</b></a>
                  </li>
                  <li>
                    prin instituțiile bancare MAIB, Moldinconbank, Victoriabank, Oficiul Poștal - la ghișeu sau prin internet Banking;
                  </li>
                  <li>
                    prin terminale și sisteme de plăți electronice (Paynet, Bpay, Runpay, Paymaster, oplata.md).
                    Pentru efectuarea plăților este necesar să selectați „servicii comunale” - operator
                    „PROFADMIN” și să introduceți „codul - ID Plătitor” din documentul de plată.
                  </li>
                </ul>
                <i><strong style={{color: '#e35f21'}}>ATENȚIE!</strong> Facturile/documente de plată, urmează a fi achitate în termenii de plată indicați în acestea. Plățile efectuate după
                  termenii de plată indicați vor fi reflectate în luna următoare celei în care au fost efectuate.</i>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span>
                  Ce se întâmplă în cazul neachitării în termen a facturilor de plată?
                </span>
                <div className="custom-accordion-button-icon"></div>
              </Accordion.Header>
              <Accordion.Body>
                <b>Termenul</b> limită de plată al <b>facturilor</b> este indicat în acestea. În cazul plăților efectuate după termenul limită de plată, sumele respective vor fi reflectate în luna următoare celei în care au fost efectuate. <br />
                <i className="mt-2 d-block">
                  <strong style={{color: '#e35f21'}}>ATENȚIE!</strong> <b>Nerespectarea</b> termenului de plată indicat, poate genera blocaje în activitatea normală a <b>Administratorului/Gestionarului</b> condominiului, inclusiv prin sistarea serviciilor de către Operatori/Furnizori și/sau aplicarea penalităților de întârziere.
                  De asemenea, <b>Nerespectarea</b> termenului de plată indicat, poate atrage după sine aplicarea sancțiunilor și penalizărilor de întârziere, după caz poate implica cheltuieli suplimentare (cum ar fi cheltuieli de judecată, de executare silită, etc.).
                </i>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span>
                  Care este necesitatea prezentării indicilor echipamentelor de măsurare (contoarelor) individuale?
                </span>
                <div className="custom-accordion-button-icon"></div>
              </Accordion.Header>
              <Accordion.Body>
                <b>Evidența/facturarea</b> consumurilor individuale contorizate se efectuează în baza indicilor echipamentelor de măsurare (contoarelor) individuale montate la nivel de unitate. <b>Nerespectarea</b> indicilor echipamentelor de măsurare (contoarelor) individuale duce la determinarea consumurilor pe baza normativelor și/sau a consumurilor medii, diferite decât consumurile reale, ceea ce poate implica penalizări/consumuri suplimentare.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="btn-wepper mt-2 mt-md-4">
            <button
              onClick={(e) => openMoal(e, "contentModal9")}
              className="bg-bth btn-read-more"
            >
              Citește mai mult <i className="fal fa-arrow-right"></i>{" "}
            </button>
          </div>
        </div>
      </div>
      <ContactModal show={show} handleClose={() => setShow(false)} />
    </div>
  );
};

export default Questions;
