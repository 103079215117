// import topImage from "../assets/top_image.png";
import AboutSlider from "../components/about-slider/AboutSlider";
import illustration from "../assets/illustration.png";
import UtilsInfo from "../components/utils-info/UtilsInfo";
import Questions from "../components/questions/Questions";
import ServicesSection from "../components/servicesSection/ServicesSection";
import HeroSlider from "../components/hero-slider/HeroSlider";
import ModalWindow from "../components/modal/Modal";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

function Main() {
  const [modelContent, setModelContent] = useState({
    e: null,
    keyName: "",
  });
  let location = useLocation();

  const openMoal = (e, name) => {
    console.log(e);

    setModelContent({e: e, keyName: name});
    // const myModal = new window.bootstrap.Modal('#exampleModal', {
    //     keyboard: false
    //   })
    //   myModal.toggle();
  };

  useEffect(() => {
    if (location.pathname === "/" && location.hash) {
      const el = document.getElementById(location.hash.replace("#", ""));
      const top = window.scrollY + el.getBoundingClientRect().top - 100;
      window.scrollTo({top, behavior: "smooth"});
    }
  }, [location]);

  return (
    <>
      <main>
        <section className="hero-wrapper hero-2">
          <HeroSlider />
        </section>
        <section id="about_section" className="about-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="block-contents text-center">
                  <div className="section-title about-title">
                    <h2>Despre companie</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row about-slider-block">
              <div className="col-md-6">
                <AboutSlider />
              </div>
              <div className="col-md-6 ml-3 about-block-contents">
                {/* <div className="block-contents">
                  <div className="section-title">
                    <span>
                      De ce să alegeți PROFADMIN SRL pentru administrarea
                      imobilului dvs?
                    </span>
                  </div>
                </div> */}
                <p className="about-slider-content">
                  <b>PROFADMIN</b> – companie specializată în gestiunea condominiilor și administrarea Asociațiilor de
                  proprietari în condominiu, înființată în anul 2018. Inclusiv, prestăm servicii de intermediar la
                  decontări, care va asigura tot procesul de calcul, emiterea facturilor de plată și distribuire a
                  serviciilor comunale în cadrul Asociației. <br />
                  Am devenit un partener de încredere pentru dezvoltatorii de construcții, furnizorii de utilități,
                  pentru locatarii din mun. Chișinău și suburbii, acumulând un portofoliu impresionant și o
                  experiență vastă în acest domeniu, la cele mai accesibile prețuri, fără a renunța la calitate. <br />
                  Echipa noastră de profesioniști asigură un personal calificat (administratori, avocați, contabili,
                  lucrători tehnici autorizați), cu experiență de peste 20 ani în domeniul prestării serviciilor
                  locativ-comunale și administrare.
                  Suntem deschiși pentru colaborare cu alte companii din domeniu serviciilor publice.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="servicii_section" className="servicii-section">
          <ServicesSection openMoal={openMoal} />
        </section>
        <section id="pay_online_section" className="pay-online-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="block-contents text-center">
                  <div className="section-title about-title">
                    <h2>Digitalizarea serviciilor</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center mb-3 mb-lg-5 pay-online-content">
              <div className="col-md-6 col-xs-12">
                <img src={illustration} />
              </div>
              <div className="col-md-6 col-xs-12 mt-3 mt-md-0">
                <div className="block-contents mb-2 mb-xl-4">
                  <div className="section-title">
                    <span>Intermediar la decontări</span>
                  </div>
                </div>
                <p className="mb-3">
                  Prin <b>Serviciile de Intermediar la Decontări</b> asigurăm un ciclu complet și digitalizat al
                  procesului de facturare.
                  Digitalizarea serviciilor în cadrul procesului de <b>Intermediar la Decontări</b> se realizează prin
                  factura pe e-mail, plata și prezentarea indicilor online, vizualizarea istoricului consumurilor și
                  achitărilor.
                </p>
                <div className="btn-wepper">
                  <button
                    onClick={(e) => openMoal(e, "contentModal5")}
                    href="#"
                    className="bg-bth btn-read-more"
                  >
                    Citește mai mult <i className="fal fa-arrow-right"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="row align-items-center pay-online-content">
              <div className="col-md-6 col-xs-12 mb-3 mb-md-0">
                <div className="block-contents mb-2 mb-xl-4">
                  <div className="section-title">
                    <span>Indicii contoarelor</span>
                  </div>
                </div>
                <p className="mb-3">
                  Prezentarea indicii contoarelor individuale se poate efectua prin orice mijloace electronice
                  puse la dispoziție de către instituțiile prestatoare de servicii de plată, sau fizic la ghișeele
                  acestora, precum și accesând butonul <a href="/factura" className="go-to-factura-link" target="_blank"><b>PREZINTĂ INDICII</b></a>.
                </p>
                <div className="btn-wepper">
                  <a
                    href="/factura"
                    className="bg-bth btn-read-more btn-send-indicators"
                    target="_blank"
                  >
                    Prezintă indicii
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="block-contents mb-2 mb-xl-4">
                  <div className="section-title">
                    <span>Plata serviciilor</span>
                  </div>
                </div>
                <p className="mb-3">
                  Facturile de plată sânt disponibile și pot fi achitate în sistemele de plăți pe întreg teritoriul
                  Republicii Moldova, precum și prin intermediul sistemelor de plăți internaționale
                  „VISA/MASTERCARD” sau accesând butonul <a href="/factura" className="go-to-factura-link" target="_blank"><b>ACHITĂ ONLINE</b></a>.
                </p>
                <div className="btn-wepper">
                  <a
                    href="/factura"
                    className="bg-bth btn-read-more btn-pay-now"
                    target="_blank"
                  >
                    Achită online
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="utils_info_section" className="utils-info-section">
          <UtilsInfo openMoal={openMoal} />
        </section>
        <section id="questions_section" className="questions-section">
          <Questions openMoal={openMoal} />
        </section>
      </main >

      <ModalWindow data={modelContent} setClose={() => setModelContent("")} />
    </>
  );
}
export default Main;
