import {useState} from "react";
import {Button} from "react-bootstrap";
import './servicesSection.css';
import icon_administration from "../../assets/icon_administration.svg";
import icon_law from "../../assets/icon_law.svg";
import icon_financial from "../../assets/icon_financial.svg";
import icon_suport from "../../assets/icon_suport.svg";
import ContactModal from "../modal/ContactModal";

const ServicesSection = ({openMoal}) => {
    const [show, setShow] = useState(false);
    return (
        <div className={"container"}>
            <div className="row">
                <div className="col-12">
                    <div className="block-contents text-center">
                        <div className="section-title about-title">
                            <h2>SERVICII</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-stretch">
                <div className="col-md-7 col-lg-8">
                    <div className="block-contents">
                        <div className="section-title mb-3">
                            <span>Pentru că suntem profesioniști, PROFADMIN Vă propune o gamă de servicii potrivit necesităților comandate, care va asigura un confort de trai al proprietarilor.</span>
                        </div>
                    </div>
                    <div className={"servicii-list"}>
                        <div>
                            {" "}
                            <span className={"icon-check"}>
                                <i className="fal fa-check"></i>
                            </span>{" "}
                        </div>
                        <p>
                            <b>GESTIONAREA CONDOMINIILOR și ADMINISTRAREA ASOCIAȚIILOR de locatari</b>,
                            inclusiv prin delegarea administratorului și/sau juristului atestați.
                        </p>
                    </div>
                    <div className={"servicii-list"}>
                        <div>
                            {" "}
                            <span className={"icon-check"}>
                                <i className="fal fa-check"></i>
                            </span>{" "}
                        </div>
                        <p>
                            <b>ADMINISTRARE FINANCIAR-CONTABILĂ</b>, inclusiv prin delegarea unui contabil, în scopul
                            asigurării tuturor proceselor de evidență financiar-contabilă ale asociațiilor.
                        </p>
                    </div>
                    <div className={"servicii-list"}>
                        <div>
                            {" "}
                            <span className={"icon-check"}>
                                <i className="fal fa-check"></i>
                            </span>{" "}
                        </div>
                        <p>
                            <b>DIGITALIZAREA SERVICIILOR comunale și necomunale</b> – Factura pe e-mail, plata și
                            prezentarea indicilor online, vizualizarea istoricului consumurilor și achitărilor;
                        </p>
                    </div>
                    <div className={"servicii-list"}>
                        <div>
                            {" "}
                            <span className={"icon-check"}>
                                <i className="fal fa-check"></i>
                            </span>{" "}
                        </div>
                        <p>
                            <b>INTERMEDIAR LA DECONTĂRI pentru Asociații de locatari și Operatorii de servicii</b> -
                            crearea bazelor de date, distribuirea consumurilor de servicii, tipărirea facturilor, pregătirea
                            rapoartelor de calcul.
                        </p>
                    </div>
                    <div className={"servicii-list"}>
                        <div>
                            {" "}
                            <span className={"icon-check"}>
                                <i className="fal fa-check"></i>
                            </span>{" "}
                        </div>
                        <p>
                            <b>MENTENANȚA TEHNICĂ</b> a sistemelor și instalațiile inginerești de uz comun (apă, canalizare, electricitate), intervenții urgente (avarii). Delegarea personalului calificat și atestat.
                        </p>
                    </div>
                    <div className={"servicii-list"}>
                        <div>
                            {" "}
                            <span className={"icon-check"}>
                                <i className="fal fa-check"></i>
                            </span>{" "}
                        </div>
                        <p>
                            <b>SALUBRIZAREA blocurilor, curților, spațiilor verzi.</b>
                        </p>
                    </div>
                    <div className={"servicii-list"}>
                        <div>
                            {" "}
                            <span className={"icon-check"}>
                                <i className="fal fa-check"></i>
                            </span>{" "}
                        </div>
                        <p>
                            <b>CURĂȚENIE AUTOMATIZATĂ a parcărilor auto și altor spații comune.</b>
                        </p>
                    </div>
                </div>
                <div className="col-md-5 offset-md-0 col-lg-4 col-sm-8 offset-sm-2 mt-4 mt-md-0">
                    <div className="card">
                        <div className="card-img">
                            <img
                                src={icon_administration}
                                className="card-img-top"
                                alt="..."
                            />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">Gama de servicii</h5>

                            <ul className="card-text">
                                <li>În dependență de necesitățile Asociației, gama de servicii și prețurile sunt negociabile.</li>
                            </ul>
                            <div className="btn-wepper">
                                <Button
                                    onClick={(e) => setShow(true)}
                                    className="bg-bth btn-full-width"
                                >
                                    Solicită oferta <i className="fal fa-arrow-right"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-4 pt-md-5">
                <div className="col-md-4 offset-md-0 col-sm-8 offset-sm-2">
                    <div className="card">
                        {/* <div className="card-img">
                            <img src={icon_suport} className="card-img-top" alt="..." />
                        </div> */}
                        <div className="card-body text-center">
                            <h5 className="card-title card-abonament">Complex</h5>
                            <ul className="card-text">
                                <li className="price-label d-flex flex-column">
                                    <span className="from-style">De la:</span>
                                    <span className="abonament-price">2,5 <small>lei/m2</small></span>
                                </li>
                            </ul>
                            <div className="btn-wepper">
                                <button
                                    onClick={(e) => openMoal(e, "contentModal4")}
                                    className="bg-bth btn-full-width"
                                >
                                    Citește mai mult <i className="fal fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 offset-md-0 col-sm-8 offset-sm-2 mt-4 mt-md-0">
                    <div className="card">
                        {/* <div className="card-img">
                            <img
                                src={icon_financial}
                                className="card-img-top"
                                alt="..."
                            />
                        </div> */}
                        <div className="card-body text-center">
                            <h5 className="card-title card-abonament">Standart</h5>
                            <ul className="card-text">
                                <li className="price-label d-flex flex-column">
                                    <span className="from-style">De la:</span>
                                    <span className="abonament-price">2,0 <small>lei/m2</small></span>
                                </li>
                            </ul>
                            <div className="btn-wepper">
                                <button
                                    onClick={(e) => openMoal(e, "contentModal3")}
                                    className="bg-bth btn-full-width "
                                >
                                    Citește mai mult <i className="fal fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 offset-md-0 col-sm-8 offset-sm-2 mt-4 mt-md-0">
                    <div className="card">
                        {/* <div className="card-img">
                            <img src={icon_law} className="card-img-top" alt="..." />
                        </div> */}
                        <div className="card-body text-center">
                            <h5 className="card-title card-abonament">Econom</h5>
                            <ul className="card-text">
                                <li className="price-label d-flex flex-column">
                                    <span className="from-style">De la:</span>
                                    <span className="abonament-price">1,5 <small>lei/m2</small></span>
                                </li>
                            </ul>
                            <div className="btn-wepper">
                                <button
                                    onClick={(e) => openMoal(e, "contentModal2")}
                                    className="bg-bth btn-full-width"
                                >
                                    Citește mai mult <i className="fal fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactModal show={show} handleClose={() => setShow(false)} />
        </div>
    )
}

export default ServicesSection;